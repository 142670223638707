import { defineModule } from '@/js/utils/helpers';
import { toggleModal } from './modals';

const onMessage = ({ data }: MessageEvent<{ event: string }>) => {
  if (!data || typeof data !== 'object' || !data.event) return;

  switch (data.event) {
    case 'close:appointment':
      toggleModal('appointment', false);
      break;
    default:
      break;
  }
};

export default defineModule(
  () => {
    window.addEventListener('message', onMessage);
  },
  () => {
    window.removeEventListener('message', onMessage);
  },
);
